import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Pagination } from 'antd';
import { Table } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { companyID } from '../../../auth';

const EmployeeWorkStatusPage = () => {
 
  const company_id  = companyID();
  let token = localStorage.getItem("token");
  const employeeID = localStorage.getItem("employeeID");

  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFullStatusModalVisible, setIsFullStatusModalVisible] = useState(false);
  const [workStatus, setWorkStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [workStatusPageSize, setWorkStatusPageSize] = useState(5);
  const [workStatusPage, setWorkStatusPage] = useState(1);
  
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}employees/employeeDetails/${company_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }); 
        setEmployees(response?.data?.filter((item) => item.id != employeeID));
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    if(company_id){
    fetchEmployees();
    }
  }, []);

  const handleEmployeeClick = async (employee) => {
    setSelectedEmployee(employee);
    setIsModalVisible(true);

    try {
      const response = await axios.get(`${config.API_BASE_URL}employee/${employee.id}/work-status`);
      setWorkStatus(response.data);
    } catch (error) {
      console.error('Error fetching work status:', error);
      setWorkStatus([]);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFullStatusClose = () => {
    setIsFullStatusModalVisible(false);
    setIsModalVisible(true);
  };

  const convertToIndianTime = (epochTime) => {
    const date = new Date(epochTime * 1000);
    return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  };

  const truncateStatus = (status, maxLength) => {
    if (status.length > maxLength) {
      return status.substring(0, maxLength) + '...';
    }
    return status;
  };

  const handleViewMore = (fullStatus) => {
    setSelectedStatus(fullStatus);
    setIsModalVisible(false);
    setIsFullStatusModalVisible(true);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Work Status',
      dataIndex: 'work_status',
      key: 'work_status',
      render: (text) => (
        <div style={{cursor: text.length > 30 ? 'pointer':''}} onClick={()=> text.length > 30 && handleViewMore(text)}>
          {truncateStatus(text, 30)}
        </div>
      )
    },
    {
      title: 'Submitted At',
      dataIndex: 'submitted_at',
      key: 'submitted_at',
      render: (submitted_at) => convertToIndianTime(submitted_at)
    }
  ];

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentEmployees = employees.slice(startIndex, endIndex);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleWorkStatusPageChange = (page, pageSize) => {
    setWorkStatusPage(page);
    setWorkStatusPageSize(pageSize);
  };

  return (
    <div className="page-wrapper">
      <div className="container mt-4">
        <h2 className="text-center mb-4">Employee Work Status</h2>

        <div className="row">
          {currentEmployees.map((employee) => (
            <div className="col-md-3 mb-4" key={employee.id}>
              <div
                className="card h-100"
                onClick={() => handleEmployeeClick(employee)}
                style={{ cursor: 'pointer' }}
              >
                <div className="card-body text-center">
                  <h5 className="card-title">
                    {employee.first_name} {employee.last_name}
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center mb-4">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={employees.length}
            showSizeChanger
            onChange={handlePageChange}
            pageSizeOptions={['5', '10', '15', '20']}
          />
        </div>

        <Modal
          title={`Work Status of ${selectedEmployee?.first_name} ${selectedEmployee?.last_name}`}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>
          ]}
        >
          {selectedEmployee && workStatus.length > 0 ? (
            <Table
              dataSource={workStatus.map((status, index) => ({
                key: index + 1,
                work_status: status.work_status,
                submitted_at: status.submitted_at
              }))}
              columns={columns}
              pagination={{
                current: workStatusPage,
                pageSize: workStatusPageSize,
                total: workStatus.length,
                showSizeChanger: true,
                onChange: handleWorkStatusPageChange,
                pageSizeOptions: ['5', '10', '15']
              }}
            />
          ) : (
            <p>No work status available for this employee.</p>
          )}
        </Modal>

        <Modal
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i 
                className="fas fa-arrow-left" 
                style={{ marginRight: '10px', cursor: 'pointer' }}
                onClick={() => {
                  handleFullStatusClose();
                }} 
              />
              <span>Full Work Status</span>
            </div>
          }
          visible={isFullStatusModalVisible}
          onCancel={handleFullStatusClose}
          footer={[
            <Button key="back" onClick={handleFullStatusClose}>
              Back
            </Button>
          ]}
        >
          <p>{selectedStatus}</p>
        </Modal>
      </div>
    </div>
  );
};

export default EmployeeWorkStatusPage;
