import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import createCustomSchema from '../../../fieldValidator/fieldValidator';
import * as Yup from "yup";
import axios from 'axios';
import config from '../../../config';
import { companyID } from '../../../auth';

const DailyWorkStatusModal = ({ show, handleClose, handleLogout }) => {
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [allowSkip, setAllowSkip] = useState(null);
  const id = localStorage.getItem("employeeID");
  const company_id = companyID();
 
  const handleSubmit = (e) => {
    console.log({status:status, employee_id:id})
    axios.post(`${config.API_BASE_URL}daily-work-status`,{work_status:status, employee_id:id})
    .then((res)=>{
        console.log('handleLogout(e); : ',res?.data)
        if(res?.data){
        handleLogout(e);
        }
    })
    .catch((err)=>console.log(err?.response?.data))
  };

  const handleSkip = (e) => {
    handleLogout(e); 
  };
 
  const validationSchema = Yup.object().shape({
    status: createCustomSchema("paragraph", {
      message: "Enter Work Status!", min:3
    }),
  });

  const onSubmit = async (e) => {
    try {
      await validationSchema.validate({status:status}, {abortEarly: false});
      handleSubmit(e);
      setStatusError({});
    } catch (error) {
      const newError = {};
      error.inner.forEach(err => {
        newError[err.path] = err.message;
      });
      setStatusError(newError);
    }
  };

  useEffect(()=>{
    const getAllowSkipConfig=()=>{
      if(company_id){
        axios.get(`${config.API_BASE_URL}company/${company_id}/allow-skip`)
      .then((res)=>{
        console.log('setAllowSkip',res?.data,'res?.data?.allow_skip', res?.data?.config?.allow_skip,)
        setAllowSkip(res?.data?.config?.allow_skip);
      })
      .catch((err)=>{
        console.log(err);
        setAllowSkip(null);
      })
      }
    }

    getAllowSkipConfig();
  },[])


  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Work Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="workStatus">
            <Form.Label>Enter your work status</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Describe what you've worked on today..."
              value={status}
              onChange={(e) => {
                setStatusError({status:null})
                setStatus(e.target.value)
            }}
            />
          </Form.Group>
          {statusError?.status &&<span style={{color:'red'}}>* {statusError?.status}</span>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {allowSkip?<Button variant="secondary" onClick={(e)=>handleSkip(e)}>
          Skip
        </Button>:<></>}
        <Button variant="primary" onClick={(e)=>onSubmit(e)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DailyWorkStatusModal;
