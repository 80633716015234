import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import login_icon from "../../../assets/img/login_icon.png";
import createCustomSchema from "../../../fieldValidator/fieldValidator";
import { isValidString } from "../../../utils/validateDateFields";
import { capitalizeWords } from "../../../utils/basicFunctions";
import IconsOfFunctionalities from './../Employees/IconsOfFunctionalities';
import Header from "../../layout/Header";
import { Sidebar } from "react-feather";
import OffCanvas from "../../../components/OffCanvas";
import FindCompanyModal from "../../../components/modelpopup/FindCompanyModal";

const Login = ({setToken}) => {
  const [validCompanyDetails , setValidCompanyDetails] = useState([]);
  const [companyUrl , setCompanyUrl] = useState(false);
  const {company_name} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [emailError, setEmailError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyColor, setCompanyColor] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [handleCompany, setHandleComapany] = useState("");
  const [emailCount, setEmailCount] = useState(0);
  const [emailCountError, setEmailCountError] = useState(false);
  const [isInValidPassword, setIsInValidPassword] = useState(false);
  const [isNavigate , setIsNavigate] = useState(false);
  const [role,setRole]=useState(null);

  console.log("company_name nitesh" , company_name); 
  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(()=>
    {
      if (isAuthenticated) {
      return;
     }
    if(company_name && isValidString(company_name)){
      console.log("not valid company...........................................")
      axios.get(`${config.API_BASE_URL}auth/login/${company_name}`)
      .then((res)=>{
        if(res?.data?.success == true){
          console.log("response from " , res);
          setValidCompanyDetails(res?.data?.result);
        }
      })
      .catch(err=>console.log(err))
    }
    else{
      console.log("not valid company")
    }
  },[companyUrl , company_name])


  const validationSchema = Yup.object().shape({
    email: createCustomSchema("email", {
      message: "Enter Your  Email!",
    }),
  });
  const details = localStorage.getItem("loginDetails");
  const loginData = JSON.parse(details);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
 

  const [authEmailRouteError , setAuthEmailRouteError] = useState("");
  const [findCompanyName , setFindCompanyName] = useState("");
  const [doneNavigation , setDoneNavigation] = useState(false);

  const validateEmail = async (email) => {
    setAuthEmailRouteError("");
    try {
      const trimmedEmail = email.trim();
      console.log("trimmed email", trimmedEmail);
      const response = await axios.post(
        `${config.API_BASE_URL}auth/validate-email`,
        { email: trimmedEmail }
      );
      console.log("repsonse" , response);
      const responseCompanyName = response?.data?.company?.trim()?.replace(/\s+/g, "-").toLowerCase();
      const roleId=response?.data?.role_id;
      setRole(roleId);
      if((responseCompanyName!== company_name)&&roleId!==1){
        setFindCompanyName(responseCompanyName);
        let message
        if(company_name){
          message = `*Your Account is not Exists in ${capitalizeWords(company_name)}. Please Login with correct URL or connect with your company administration! `
        }else{
          message='Please Login with correct URL or connect with your company administration!'
        }
        
        setAuthEmailRouteError(message);
        setStep(1);
        navigate(window.location.pathname, { replace: true })
        // navigate(`../${responseCompanyName}/`)
        setCompanyUrl(true);
        return;
      }
      console.log("emailexists--->", response);
      return response?.data;
    } catch (error) {
      return false;
    }
  };

  const handleEmailSubmit = async (data) => {
    setValue("email", data.email);
    if (!data?.email) {
      setEmailError(true);
      return;
    }
    const emailIsValid = await validateEmail(data.email);
    if (emailIsValid?.exists) {
      localStorage.setItem("invalidUser", data?.email);
      if (emailIsValid?.match === false) {
        localStorage.setItem("company_url", emailIsValid?.company?.trim()?.replace(/\s+/g, "-").toLowerCase());
        const company = extractCompanyName(data?.email);
        console.log("handle company name--->", company);
        setCompanyName(company);
        setStep(2);
        // setEmailError(false);
        setEmailCountError(false);
      } else {
        navigate("/forgot-password");
      }
    } else {
      if (emailCount > 2) {
        setEmailCountError(true);
      } else {
        setEmailError(true);
        setEmailCount((prev) => prev + 1);
        setEmailCountError(false);
      }
    }
  };


  const extractCompanyName = (email) => {
    const domain = email.split("@")[1];
    const company = domain.split(".")[0];
    console.log("company name exists--->", company);
    return company;
  };

  const handleLoginSubmit = async (data) => {
    console.log("calling data", data);
    if (!data?.password) {
      console.log("calling data  from login submit", data);
      setPasswordError("This Feild is Required!");
      setIsInValidPassword(false);
      return;
    }
    setPasswordError("");
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}auth/login`,
        data
      );
      if (response.status === 200) {
        const { token, user } = response.data;
        const responseCompanyName = user?.company?.name?.trim()?.replace(/\s+/g, "-").toLowerCase();
        if(company_name  === responseCompanyName ||role===1 ){
          const { exp } = JSON.parse(atob(token.split(".")[1]));
          const expirationTime = exp * 1000;
          localStorage.setItem("token_expiration", expirationTime.toString());
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("colorschema", user.company.colour);
          localStorage.setItem("companyColor", user.company.colour);
          localStorage.setItem("companyName", user.company.name);
          localStorage.setItem("employee_role", user?.employee_role);
  
          setCompanyColor(user?.company?.colour); 
          dispatch(login(user));
          setIsNavigate(true);
          setToken(token)
          toast.success("Login successful!");
          // navigate(window.location.pathname, { replace: true })
          navigate(`../${responseCompanyName}/`);
        }else{
         
        }
        
      } else {
        setEmailError(true);
        setIsInValidPassword(true);
      }
    } catch (error) {
      console.log("err", error);
      setEmailError(true);
      setIsInValidPassword(true);
    }
  };


  useEffect(() => {
    setValue("email", localStorage.getItem("email"));
    setValue("password", localStorage.getItem("password"));
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  useEffect(() => {
    const company_url=localStorage.getItem('company_url')
    if (isAuthenticated) {
      // navigate(`../${company_name}/`);
      navigate(window.location.pathname, { replace: true })
    }
    if(company_url){
      navigate(`../${company_url}`)
    }
  }, [isAuthenticated, navigate, company_name]);

  useEffect(()=>{
    setAuthEmailRouteError("");
    setEmailError(false);
  },[doneNavigation])

 if(isAuthenticated){
  const companyName = localStorage.getItem("companyName");
  navigate(`../${companyName.trim()?.replace(/\s+/g, "-").toLowerCase()}`);
  return;
 }

  return (
    <>
    <div>
      <ToastContainer />
      <div className="account-page" style={{ backgroundColor: validCompanyDetails[0]?.colour }}>
        <div className="main-wrapper">
          <div className="account-content">
            {/*<Link to="/job-list" className="btn btn-primary apply-btn">
              Apply Job
            </Link> */}
            <div className="container" >
              {/* Account Logo */}
              <div className="account-logo" >
                <Link to="#" >
                  <img src={validCompanyDetails[0]?.company_logo ?`${config.IMAGE_URL}${validCompanyDetails[0]?.company_logo}` :login_icon} alt={validCompanyDetails[0]?.company_name}  style={{borderRadius:"50%" ,zIndex:"100" ,  backgroundColor:"white"  , width:"150px" ,height:"150px"}} />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">
                    Access to {validCompanyDetails[0]?.company_name?capitalizeWords(validCompanyDetails[0]?.company_name):'your'} dashboard
                  </p>
                  {/* Account Form */}
                  <div>
                    {step === 1 ? (
                      <form onSubmit={handleSubmit(handleEmailSubmit)}>
                        <div className="input-block mb-4">
                          <label className="col-form-label">
                            Email Address
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  emailError ? "error-input" : ""
                                }`}
                                type="text"
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                                autoFocus={true}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {(authEmailRouteError &&( authEmailRouteError)) || errors.email?.message ||
                              (emailError && "*Please Enter Valid Email!") ||
                              (emailCountError &&
                                "*Please Contact Your Organisation !")  }
                          </span>
                        </div>
                        <div className="input-block text-center">
                          <button
                            className="btn btn-primary account-btn"
                            type="submit"
                          >
                            Validate email
                          </button>
                        </div>
                        {authEmailRouteError && 
                        ( <>
                           Help - {" "}
                         <Link to="#" 
                          data-bs-toggle="modal"
                          data-bs-target="#find_company"
                         style={{color:"#201a7a" , fontSize: "14px"}}>Find Your Company URL</Link>
                        </>)}
                      </form>
                    ) : (
                      <form onSubmit={handleSubmit(handleLoginSubmit)}>
                        <div className="input-block mb-4">
                          <div className="row">
                            <div className="col">
                              <label className="col-form-label">Password</label>
                            </div>
                            <div className="col-auto">
                              <Link
                                className="text-muted"
                                to="/forgot-password"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <Controller
                              name="password"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.password ? "error-input" : ""
                                  }`}
                                  type={eye ? "password" : "text"}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoFocus={true}
                                />
                              )}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={onEyeClick}
                              className={`fa-solid ${
                                eye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>

                          <span className="text-danger">
                            {errors.password?.message || passwordError}
                          </span>
                          {isInValidPassword && (
                            <span className="text-danger">
                              Please enter correct password.
                            </span>
                          )}
                        </div>
                        <div className="input-block text-center">
                          {/* <Link to = {`/`}> */}
                          <button
                            className="btn btn-primary account-btn"
                            type="submit"
                          >
                            Login
                          </button>
                          {/* </Link> */}
                        </div>
                      </form>
                    )}

                    
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<FindCompanyModal  findCompanyName={findCompanyName}  setDoneNavigation ={setDoneNavigation} />
</>
  );
};

export default Login;
